import type { KeyingConfig } from '@urql/exchange-graphcache';

const createGuidKeyConfig = (typenames: string[]): KeyingConfig => {
  return typenames.reduce<KeyingConfig>((config, typename) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config[typename] = (data: any) => data.guidId;
    return config;
  }, {});
};

const createNullKeyConfig = (typenames: string[]): KeyingConfig => {
  return typenames.reduce<KeyingConfig>((config, typename) => {
    config[typename] = () => null;
    return config;
  }, {});
};

const keyingConfig: KeyingConfig = {
  ...createGuidKeyConfig([
    'Connection',
    'CodeList',
    'Field',
    'Page',
    'CosmeticItem',
    'ObjectBase',
    'HandwritingRecognitionObject',
    'TickboxGroup',
    'ManualImageAreaText',
    'PickList',
    'Image',
    'SubQuestionField',
    'TextBox',
    'UrlLink',
    'QuestionCaption',
    'Gauge',
    // 'Line',
    // 'Rectangle',
    // 'AutomaticImageArea',
  ]),
  ...createNullKeyConfig([
    'Code',
    'CaptionAlignment',
    'Dimensions',
    'Location',
    'OnlineOptions',
    'OnlinePermissions',
    'Responses',
    'RespondentIdentifier',
    'ImageObject',
    'RoutingCondition',
    'RoutingRule',
    'DataRelation',
    'DataRelationDescriptor',
    'GlobalOnlineFormsOptions',
    'Tickbox',
    'TickboxResponse',
    'HandwritingRecognitionObjectBox',
    'ProjectTranslation',
    'Rectangle',
    'RoundedRectangle',
    'BorderObject',
    'BorderStyleProperties',
    'BorderMargins',
    'DataDerivationResult',
    'ProcessingError',
    'Routing',
  ]),
  // TODO: Provide better error reporting when these are undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Project: (data: any) => data.id.toString(),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Respondent: (data: any) => data.receipt,
};

export default keyingConfig;
