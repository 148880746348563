export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  Regex: any;
  Url: string;
  Uuid: string;
};




export enum AlternateBackStyle {
  None = 'NONE',
  Rows = 'ROWS',
  Columns = 'COLUMNS'
}

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export type BorderMargins = {
  __typename?: 'BorderMargins';
  left?: Maybe<Scalars['Int']>;
  top?: Maybe<Scalars['Int']>;
  right?: Maybe<Scalars['Int']>;
  bottom?: Maybe<Scalars['Int']>;
};

export type BorderObject = ObjectBase & {
  __typename?: 'BorderObject';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  borderMargins?: Maybe<BorderMargins>;
  borderStyleProperties?: Maybe<BorderStyleProperties>;
  legacyCosmeticItemType?: Maybe<CosmeticItemType>;
  backColor?: Maybe<Scalars['String']>;
  foreColor?: Maybe<Scalars['String']>;
};

export enum BorderStyle {
  None = 'NONE',
  Top = 'TOP',
  Left = 'LEFT',
  Bottom = 'BOTTOM',
  Right = 'RIGHT',
  All = 'ALL'
}

export type BorderStyleProperties = {
  __typename?: 'BorderStyleProperties';
  borders?: Maybe<Scalars['String']>;
  borderWeight?: Maybe<BorderWeight>;
  shadowWeight?: Maybe<Scalars['Int']>;
  penStyle?: Maybe<PenStyle>;
};

export enum BorderWeight {
  None = 'NONE',
  QuarterPoint = 'QUARTER_POINT',
  HalfPoint = 'HALF_POINT',
  ThreeQuarter = 'THREE_QUARTER',
  OnePoint = 'ONE_POINT',
  OneHalfPoint = 'ONE_HALF_POINT',
  TwoQuarterPoint = 'TWO_QUARTER_POINT',
  ThreePoint = 'THREE_POINT',
  FourHalfPoint = 'FOUR_HALF_POINT',
  SixPoint = 'SIX_POINT'
}

export enum BoxLabelPosition {
  Left = 'LEFT',
  Right = 'RIGHT',
  Above = 'ABOVE',
  Below = 'BELOW',
  Inside = 'INSIDE'
}

export type CaptionAlignment = {
  __typename?: 'CaptionAlignment';
  horizontalAlignment: HorizontalAlignment;
  horizontalOffset: Scalars['Int'];
  relativeLayout: RelativeLayout;
  verticalAlignment: VerticalAlignment;
  verticalOffset: Scalars['Int'];
};

export type CaptureObjectBase = {
  /** The size of this object on the page. */
  dimensions: Dimensions;
  fieldId: Scalars['Uuid'];
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  name: Scalars['String'];
  questionCaption?: Maybe<QuestionCaption>;
};

/** A coded equivalent of the ordinal value of a capture object; i.e. a label. */
export type Code = {
  __typename?: 'Code';
  guidId: Scalars['Uuid'];
  /** Ordinal value\index of the code. */
  index: Scalars['Int'];
  /** Code value. */
  text?: Maybe<Scalars['String']>;
};

/** Contains a collection of Codes and functions relevant to the loading and general management of a CodeList. */
export type CodeList = {
  __typename?: 'CodeList';
  captionColumn?: Maybe<Scalars['String']>;
  codes: Array<Code>;
  connectionId?: Maybe<Scalars['Uuid']>;
  guidId: Scalars['Uuid'];
  isExternal: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  searchColumn?: Maybe<Scalars['String']>;
};


/** Contains a collection of Codes and functions relevant to the loading and general management of a CodeList. */
export type CodeListCodesArgs = {
  skipExternal?: Maybe<Scalars['Boolean']>;
};

export type Colours = {
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export type CompletionAction = {
  __typename?: 'CompletionAction';
  redirectImmediately: Scalars['Boolean'];
  redirectionDelay: Scalars['Int'];
  redirectUri?: Maybe<Scalars['Url']>;
};

export type Connection = {
  __typename?: 'Connection';
  columns: Array<Scalars['String']>;
  guidId: Scalars['Uuid'];
  jsonData?: Maybe<Scalars['String']>;
  relations: Array<DataRelation>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  x1: Scalars['Int'];
  y1: Scalars['Int'];
  x2: Scalars['Int'];
  y2: Scalars['Int'];
};

export type CosmeticItem = ObjectBase & DesignObjectBase & Colours & {
  __typename?: 'CosmeticItem';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  htmlText?: Maybe<Scalars['String']>;
  /** The location of this object on the page. */
  location: Location;
  questionCaption?: Maybe<QuestionCaption>;
  text?: Maybe<Scalars['String']>;
  textRotation: Scalars['Int'];
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export enum CosmeticItemType {
  Text = 'TEXT',
  Barcode = 'BARCODE',
  Rectangle = 'RECTANGLE',
  RoundedRectangle = 'ROUNDED_RECTANGLE',
  Circle = 'CIRCLE',
  Line = 'LINE',
  Image = 'IMAGE',
  Autoshape = 'AUTOSHAPE',
  Rtftext = 'RTFTEXT',
  UniformResourceLocator = 'UNIFORM_RESOURCE_LOCATOR',
  LinkedDatabase = 'LINKED_DATABASE',
  QuestionCaption = 'QUESTION_CAPTION',
  BorderObject = 'BORDER_OBJECT',
  Default = 'DEFAULT'
}

export type CsvDownloadPermissions = {
  canDownloadSubmittedDataAsCsv: Scalars['Boolean'];
};

export type DataDerivationRequestInput = {
  projectId: Scalars['Int'];
  responses: Array<FieldResponseInput>;
  context: ProcessRequestContext;
};

export type DataDerivationResult = {
  __typename?: 'DataDerivationResult';
  respondent: Respondent;
  processingErrors?: Maybe<Array<Maybe<ProcessingError>>>;
};

export type DataRelation = {
  __typename?: 'DataRelation';
  columnName: Scalars['String'];
  relation: DataRelationDescriptor;
};

export type DataRelationDescriptor = {
  __typename?: 'DataRelationDescriptor';
  columnName: Scalars['String'];
  connection?: Maybe<Array<Maybe<Connection>>>;
  connectionId: Scalars['Uuid'];
};

export enum DataType {
  AlphaNumeric = 'ALPHA_NUMERIC',
  Coded = 'CODED',
  Numeric = 'NUMERIC',
  DateTime = 'DATE_TIME'
}


export type DeleteRespondentResult = {
  __typename?: 'DeleteRespondentResult';
  isSuccess: Scalars['Boolean'];
  message: Scalars['String'];
  resultCode: ServiceResultCode;
};

export type DesignObjectBase = {
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  questionCaption?: Maybe<QuestionCaption>;
};

export type DesignObjectsUnion = Image | TextBox | UrlLink | CosmeticItem | Rectangle | RoundedRectangle | Line | HandwritingRecognitionObject | ManualImageAreaText | PickList | TickboxGroup | Gauge;

export type Dimensions = {
  __typename?: 'Dimensions';
  /** Height of the object in twips. */
  height: Scalars['Int'];
  /** Width of the object in twips. */
  width: Scalars['Int'];
};

export enum ExpectedCase {
  UpperCaseOnly = 'UPPER_CASE_ONLY',
  LowerCaseOnly = 'LOWER_CASE_ONLY',
  BothCases = 'BOTH_CASES'
}

/**
 * In Fusion, each question object in the form design has a `Field` associated with it. The `Field`
 * contains information about what type of data it holds, how the data should be formatted and
 * validated and, in some cases, whether it should fetch data from a remote source.
 */
export type Field = {
  __typename?: 'Field';
  /**
   * Specifies whether derived data for this field is allowed to overwrite any data that has
   * been captured by it.
   */
  allowDerivedDataToOverWrite: Scalars['Boolean'];
  connection: Array<Maybe<Connection>>;
  caseSensitiveValidation: Scalars['Boolean'];
  checkFormat: Scalars['Boolean'];
  codeList?: Maybe<CodeList>;
  dataTypeNew: DataType;
  /** For numeric data types, how many decimal points the captured data is rounded to. */
  decimals: Scalars['Int'];
  /** An expression describing how this field's data is derived. */
  derivation?: Maybe<Scalars['String']>;
  /** Short description of the field. */
  description?: Maybe<Scalars['String']>;
  /** This seems to do lots of different things.. */
  digits: Scalars['Int'];
  /** Defines string-related rules that should apply to data captured in this field. */
  fieldFormat: Array<FieldFormat>;
  /** No idea what this is. */
  fieldProcesses?: Maybe<Scalars['String']>;
  /** The specific type of data that is captured by this field. */
  fieldType: FieldType;
  /** A mask describing the format of the data captured by this field in Fusion notation. */
  formatMask?: Maybe<Scalars['String']>;
  /** A mask describing the format of the data captured by this field as a Regex. */
  formatRegex?: Maybe<Scalars['Regex']>;
  /** GUID of this field. */
  guidId: Scalars['Uuid'];
  /** Numeric unique ID of this field. */
  id: Scalars['Int'];
  /** Indicates whether data captured by this field is required. */
  mandatory: Scalars['Boolean'];
  /** A unique and descriptive name to identify this field. */
  name: Scalars['String'];
  /**
   * ID of the field that directly filters the available values in this field.
   * Used only in coded fields.
   */
  parentFieldIds: Array<Scalars['Uuid']>;
  /**
   * An additional description for the field that allows for categorising and collating
   * multiple fields together.
   */
  questionTag?: Maybe<Scalars['String']>;
  /** For coded fields, describes the number of minimum required and maximum allowed responses. */
  responses?: Maybe<Responses>;
  /**
   * For fields with external data sources, specifies the column of the data source that is
   * used to search by.
   */
  searchColumn?: Maybe<Scalars['String']>;
  /** The message shown to the respondent when there is a validation error. */
  userValidationErrorMessage?: Maybe<Scalars['String']>;
  /** An expression describing how this field's data is validated. */
  validation?: Maybe<Scalars['String']>;
};

export enum FieldFormat {
  FixedDecimals = 'FIXED_DECIMALS',
  FixedDigits = 'FIXED_DIGITS',
  TrimLeadingSpaces = 'TRIM_LEADING_SPACES',
  TrimTrailingSpaces = 'TRIM_TRAILING_SPACES',
  TrimAllSpaces = 'TRIM_ALL_SPACES',
  UpperCase = 'UPPER_CASE',
  LowerCase = 'LOWER_CASE',
  RemoveAllNonNumerics = 'REMOVE_ALL_NON_NUMERICS',
  RemoveAllCrlf = 'REMOVE_ALL_CRLF',
  TrimLeadingTrailingCrlf = 'TRIM_LEADING_TRAILING_CRLF',
  ConvertToDecimal = 'CONVERT_TO_DECIMAL'
}

export type FieldResponse = {
  __typename?: 'FieldResponse';
  id: Scalars['Uuid'];
  value: Scalars['String'];
};

export type FieldResponseInput = {
  id: Scalars['Uuid'];
  value: Scalars['String'];
};

export enum FieldStateAction {
  Enable = 'ENABLE',
  Disable = 'DISABLE'
}

export enum FieldType {
  Any = 'ANY',
  PostCode = 'POST_CODE',
  Surname = 'SURNAME',
  TelephoneNumber = 'TELEPHONE_NUMBER',
  Address = 'ADDRESS',
  BankSortCode = 'BANK_SORT_CODE',
  EMailAddress = 'E_MAIL_ADDRESS',
  CreditCardNumber = 'CREDIT_CARD_NUMBER',
  AccountNumber = 'ACCOUNT_NUMBER',
  Custom = 'CUSTOM',
  CurrentDateTime = 'CURRENT_DATE_TIME',
  ImageFileName = 'IMAGE_FILE_NAME',
  CodeListData = 'CODE_LIST_DATA',
  InstanceValue = 'INSTANCE_VALUE',
  WebFormsDisplayName = 'WEB_FORMS_DISPLAY_NAME',
  OperatorUserName = 'OPERATOR_USER_NAME',
  OperatorDisplayName = 'OPERATOR_DISPLAY_NAME',
  WebFormsUserName = 'WEB_FORMS_USER_NAME',
  NhsNumber = 'NHS_NUMBER'
}

export enum FontAttributes {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Underline = 'UNDERLINE',
  Strikeout = 'STRIKEOUT',
  Superscript = 'SUPERSCRIPT',
  Subscript = 'SUBSCRIPT'
}

export type Gauge = ObjectBase & DesignObjectBase & CaptureObjectBase & {
  __typename?: 'Gauge';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  fieldId: Scalars['Uuid'];
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  name: Scalars['String'];
  questionCaption?: Maybe<QuestionCaption>;
  startValue: Scalars['Int'];
  endValue: Scalars['Int'];
  showTickValues?: Maybe<Scalars['Boolean']>;
  tickFrequency: Scalars['Int'];
  gaugeTickValueColour?: Maybe<Scalars['String']>;
  gaugeLineColour?: Maybe<Scalars['String']>;
  gaugeMarkerColour?: Maybe<Scalars['String']>;
  borderObject?: Maybe<BorderObject>;
  borderMargins?: Maybe<BorderMargins>;
  borderStyleProperties?: Maybe<BorderStyleProperties>;
  /** used for tick values */
  fontAttributes?: Maybe<FontAttributes>;
  fontName?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['Int']>;
};

export type GlobalOnlineFormsOptions = {
  __typename?: 'GlobalOnlineFormsOptions';
  displayProjectsOnHomeScreen: Scalars['Boolean'];
  displayTagEntryOnHomeScreen: Scalars['Boolean'];
  encodeReceipts: Scalars['Boolean'];
  groupCodesEnabled: Scalars['Boolean'];
  loginEnabled: Scalars['Boolean'];
  registrationEnabled: Scalars['Boolean'];
  userUpdateEnabled: Scalars['Boolean'];
};

export type GroupInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type HandwritingRecognitionObject = ObjectBase & DesignObjectBase & CaptureObjectBase & Colours & {
  __typename?: 'HandwritingRecognitionObject';
  borderWeight: BorderWeight;
  boxes: Array<HandwritingRecognitionObjectBox>;
  boxLabelPosition: BoxLabelPosition;
  cosmeticItems: Array<CosmeticItem>;
  /** The size of this object on the page. */
  dimensions: Dimensions;
  expectedCase: ExpectedCase;
  fieldId: Scalars['Uuid'];
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  layer: Scalars['Int'];
  legend?: Maybe<Scalars['String']>;
  /** The location of this object on the page. */
  location: Location;
  name: Scalars['String'];
  questionCaption?: Maybe<QuestionCaption>;
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export type HandwritingRecognitionObjectBox = ObjectBase & {
  __typename?: 'HandwritingRecognitionObjectBox';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  ordinal: Scalars['Int'];
};

export enum HorizontalAlignment {
  Left = 'LEFT',
  Centre = 'CENTRE',
  Right = 'RIGHT'
}

export enum IdentificationMethod {
  MasterBarcode = 'MASTER_BARCODE',
  Algorithm = 'ALGORITHM',
  Field = 'FIELD',
  Manual = 'MANUAL',
  None = 'NONE',
  Automatic = 'AUTOMATIC',
  IncrementDecrementPageNumber = 'INCREMENT_DECREMENT_PAGE_NUMBER',
  MasterBarcodePage1Only = 'MASTER_BARCODE_PAGE1_ONLY'
}

export type Image = ObjectBase & DesignObjectBase & Colours & {
  __typename?: 'Image';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  htmlText?: Maybe<Scalars['String']>;
  imageObject: ImageObject;
  /** The location of this object on the page. */
  location: Location;
  questionCaption?: Maybe<QuestionCaption>;
  text?: Maybe<Scalars['String']>;
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export type ImageObject = {
  __typename?: 'ImageObject';
  binaryId: Scalars['Uuid'];
  filename?: Maybe<Scalars['String']>;
  height: Scalars['Int'];
  scalingMode: ImageScalingMode;
  width: Scalars['Int'];
};

export enum ImageScalingMode {
  StretchToFit = 'STRETCH_TO_FIT',
  MaintainAspectRatio = 'MAINTAIN_ASPECT_RATIO',
  FullSize = 'FULL_SIZE'
}

export type LdbRequestInput = {
  lookupResponses: Array<Maybe<FieldResponseInput>>;
  projectId: Scalars['Int'];
};

export type Line = ObjectBase & DesignObjectBase & Colours & {
  __typename?: 'Line';
  borderStyleProperties?: Maybe<BorderStyleProperties>;
  foreColor?: Maybe<Scalars['String']>;
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  questionCaption?: Maybe<QuestionCaption>;
  coordinates: Coordinates;
  backColor?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  /** Layering level of the object. */
  layer: Scalars['Int'];
  /** Left co-ordinate of the object in twips. */
  left: Scalars['Int'];
  /** Top co-ordinate of the object in twips. */
  top: Scalars['Int'];
};

export type ManualImageAreaText = ObjectBase & DesignObjectBase & CaptureObjectBase & Colours & {
  __typename?: 'ManualImageAreaText';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  fieldId: Scalars['Uuid'];
  guidelineMargin: Scalars['Int'];
  guidelines: Scalars['Boolean'];
  guidelineWeight: Scalars['Int'];
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  name: Scalars['String'];
  numberOfGuidelines: Scalars['Int'];
  questionCaption?: Maybe<QuestionCaption>;
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  importUsers: Array<WebUser>;
  updateRespondent: SubmissionResult;
  deleteRespondent: DeleteRespondentResult;
};


export type MutationImportUsersArgs = {
  userInput: Array<UserInput>;
};


export type MutationUpdateRespondentArgs = {
  submission: SubmissionInput;
};


export type MutationDeleteRespondentArgs = {
  receipt: Scalars['String'];
};

export enum NavigationType {
  External = 'EXTERNAL',
  Page = 'PAGE',
  Field = 'FIELD'
}

export type ObjectBase = {
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
};

export type OnlineOptions = {
  __typename?: 'OnlineOptions';
  isAvailableOffline: Scalars['Boolean'];
  isVisibleInContents: Scalars['Boolean'];
  redirectionUrl?: Maybe<Scalars['String']>;
  skinName?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
};

export type OnlinePermissions = {
  __typename?: 'OnlinePermissions';
  canDownloadSubmissionAsPdf: Scalars['Boolean'];
  canEditSubmitted: Scalars['Boolean'];
  canReloadSubmitted: Scalars['Boolean'];
  canSavePartialForms: Scalars['Boolean'];
  userAuthorisationEnabled: Scalars['Boolean'];
  responsiveRendering: Scalars['Boolean'];
  allowRespondentLanguageSelection: Scalars['Boolean'];
  userSubmissionCountRestricted: Scalars['Boolean'];
};

export type Page = {
  __typename?: 'Page';
  dimensions: Dimensions;
  guidId: Scalars['Uuid'];
  objects: Array<DesignObjectsUnion>;
  pageId: Scalars['Int'];
  pageOrientation: PageOrientation;
};

export enum PageNavigationType {
  None = 'NONE',
  PreviousPage = 'PREVIOUS_PAGE',
  NextPage = 'NEXT_PAGE',
  PageNumber = 'PAGE_NUMBER',
  Cancel = 'CANCEL',
  Clear = 'CLEAR',
  Save = 'SAVE',
  Submit = 'SUBMIT'
}

export enum PageOrientation {
  Portrait = 'PORTRAIT',
  /** Landscape paper orientation. */
  Landscape = 'LANDSCAPE'
}

export type PdfDownloadPermissions = {
  canDownloadSubmissionAsPdf: Scalars['Boolean'];
};

export enum PenStyle {
  Solid = 'SOLID',
  Dash = 'DASH',
  Dot = 'DOT',
  DashDot = 'DASH_DOT',
  DashDotDot = 'DASH_DOT_DOT',
  Null = 'NULL'
}

export type PickList = ObjectBase & DesignObjectBase & CaptureObjectBase & Colours & {
  __typename?: 'PickList';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  fieldId: Scalars['Uuid'];
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  name: Scalars['String'];
  questionCaption?: Maybe<QuestionCaption>;
  responses?: Maybe<Responses>;
  sort: Scalars['Boolean'];
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export enum ProcessRequestContext {
  Load = 'LOAD',
  Update = 'UPDATE',
  Submit = 'SUBMIT'
}

export type ProcessingError = {
  __typename?: 'ProcessingError';
  fieldId: Scalars['Uuid'];
  processingErrorMessages: Array<Scalars['String']>;
};

/** The term project is used to mean all the files created and stored by Fusion related to a form design. */
export type Project = {
  __typename?: 'Project';
  /** The user or entity that created the project. */
  author?: Maybe<Scalars['String']>;
  connections: Array<Connection>;
  fields: Array<Field>;
  /** A description of this project and the goals it aims to achieve. */
  goals?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** The method used to identify the project. */
  identificationMethod: IdentificationMethod;
  currentCompletionCount?: Maybe<Scalars['Int']>;
  maxCompletionCount?: Maybe<Scalars['Int']>;
  currentMaxUserCompletionCount?: Maybe<Scalars['Int']>;
  maxUserCompletionCount?: Maybe<Scalars['Int']>;
  /** The name of this project. */
  name: Scalars['String'];
  /** Online-specific settings. */
  onlineOptions: OnlineOptions;
  /** Permissions applied to the project when it is accessed online. */
  onlinePermissions: OnlinePermissions;
  /** Contains a collection of the project's pages. */
  pages: Array<Page>;
  projectTranslations: Array<ProjectTranslation>;
  /** The mediums that this project is published to and distributed via. */
  publicationMedia: Array<PublicationMediaTypes>;
  /** The method used to identify individual respondents. */
  respondentIdMethod: IdentificationMethod;
  /** Contains a collection of question routing rules in the project. */
  routing: Array<RoutingRule>;
  /** Date and time when this project was created. */
  startDate: Scalars['DateTime'];
  /**
   * The ID of the field that is used to uniquely identify responses. Only applicable when
   * the `respondentIdMethod` is set to `Field`.
   */
  uniqueFieldGuid: Scalars['Uuid'];
  /** The current version of the form design. */
  version: Scalars['Int'];
};

export type ProjectIdentifierInput = {
  id?: Maybe<Scalars['Int']>;
  tagName?: Maybe<Scalars['String']>;
};

export type ProjectLanguage = {
  __typename?: 'ProjectLanguage';
  id: Scalars['String'];
  language: Scalars['String'];
  nativeLanguage: Scalars['String'];
};

export type ProjectTranslation = {
  __typename?: 'ProjectTranslation';
  projectName: Scalars['String'];
  subTag: Scalars['String'];
  nativeLanguage: Scalars['String'];
  translations: Scalars['String'];
};

export enum PublicationMediaTypes {
  Unknown = 'UNKNOWN',
  /** Enabled for paper capture. This is the default medium for all projects. */
  Paper = 'PAPER',
  /** Enabled for online capture. Forms can be accessed and completed via a web browser. */
  Html = 'HTML',
  /** Design is based on a non-Fusion (legacy) form. */
  Legacy = 'LEGACY',
  /** Enabled for IDR. No longer used. */
  Idr = 'IDR'
}

export type Query = {
  __typename?: 'Query';
  codeList: CodeList;
  connection: Connection;
  derivedData: DataDerivationResult;
  ldbData: Respondent;
  linkedDbAndDerivedData: DataDerivationResult;
  onlineFormsOptions: GlobalOnlineFormsOptions;
  partialRespondents: Array<Respondent>;
  project: Project;
  projectPage: Page;
  projectAvailableTranslations: Array<ProjectLanguage>;
  projects: Array<Project>;
  respondent: Respondent;
  recordsMedia: Array<RecordsMedia>;
  user: WebUser;
  webTheme: WebTheme;
  webThemes: Array<WebTheme>;
};


export type QueryCodeListArgs = {
  codeListId: Scalars['Uuid'];
  projectIdentifier: ProjectIdentifierInput;
};


export type QueryConnectionArgs = {
  connectionId: Scalars['Uuid'];
  projectIdentifier: ProjectIdentifierInput;
};


export type QueryDerivedDataArgs = {
  input?: Maybe<DataDerivationRequestInput>;
};


export type QueryLdbDataArgs = {
  input?: Maybe<LdbRequestInput>;
};


export type QueryLinkedDbAndDerivedDataArgs = {
  input?: Maybe<DataDerivationRequestInput>;
};


export type QueryPartialRespondentsArgs = {
  projectId?: Maybe<Scalars['Int']>;
};


export type QueryProjectArgs = {
  projectIdentifier: ProjectIdentifierInput;
};


export type QueryProjectPageArgs = {
  pageNumber: Scalars['Int'];
  projectIdentifier: ProjectIdentifierInput;
};


export type QueryProjectAvailableTranslationsArgs = {
  projectId: Scalars['Int'];
};


export type QueryRespondentArgs = {
  receipt: Scalars['String'];
};


export type QueryRecordsMediaArgs = {
  receipt: Scalars['String'];
};


export type QueryUserArgs = {
  userIdentifiers: UserIdentifiersInput;
};


export type QueryWebThemeArgs = {
  themeId: Scalars['Int'];
};

export type QuestionCaption = ObjectBase & Colours & {
  __typename?: 'QuestionCaption';
  captionAlignment?: Maybe<CaptionAlignment>;
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  htmlText?: Maybe<Scalars['String']>;
  /** The location of this object on the page. */
  location: Location;
  text?: Maybe<Scalars['String']>;
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export type RecordsMedia = {
  __typename?: 'RecordsMedia';
  id: Scalars['Int'];
  projectNumber: Scalars['Int'];
  fieldId: Scalars['Uuid'];
  level: Scalars['Int'];
  fileName: Scalars['String'];
  data: Scalars['String'];
  fileType: Scalars['String'];
};

export type Rectangle = ObjectBase & DesignObjectBase & Colours & {
  __typename?: 'Rectangle';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  questionCaption?: Maybe<QuestionCaption>;
  borderObject?: Maybe<BorderObject>;
  borderMargins?: Maybe<BorderMargins>;
  borderStyleProperties?: Maybe<BorderStyleProperties>;
  backColor?: Maybe<Scalars['String']>;
  foreColor?: Maybe<Scalars['String']>;
};


export enum RelativeLayout {
  None = 'NONE',
  Left = 'LEFT',
  Top = 'TOP',
  Right = 'RIGHT',
  Bottom = 'BOTTOM',
  Centre = 'CENTRE'
}

export type Respondent = {
  __typename?: 'Respondent';
  identifier: RespondentIdentifier;
  receipt?: Maybe<Scalars['String']>;
  responses: Array<FieldResponse>;
  submissionDate?: Maybe<Scalars['DateTime']>;
  language: Scalars['String'];
};

export type RespondentIdentifier = {
  __typename?: 'RespondentIdentifier';
  project: Project;
  projectId: Scalars['Int'];
  recordId: Scalars['Int'];
  status: RespondentStatus;
  uniqueIdData?: Maybe<Scalars['String']>;
  webUserId?: Maybe<Scalars['Int']>;
};

export enum RespondentOperation {
  Save = 'SAVE',
  Submit = 'SUBMIT'
}

export enum RespondentStatus {
  New = 'NEW',
  Saved = 'SAVED',
  Submitted = 'SUBMITTED'
}

export type ResponsePositioning = {
  __typename?: 'ResponsePositioning';
  responseHorizontalAlignment: HorizontalAlignment;
  responseHorizontalOffset: Scalars['Int'];
  responsePosition: RelativeLayout;
  responseVerticalAlignment: VerticalAlignment;
  responseVerticalOffset: Scalars['Int'];
};

export type Responses = {
  __typename?: 'Responses';
  /** Indicates whether this field allows multiple responses. */
  isMultiResponse: Scalars['Boolean'];
  /**
   * Indicates the maximum number of responses allowed on this field. If this value is zero,
   * then there is no limit on the maximum number of responses.
   */
  maximum: Scalars['Int'];
  /** Indicates the minimum number of responses required on this field. */
  minimum: Scalars['Int'];
};

export type RoundedRectangle = ObjectBase & DesignObjectBase & Colours & {
  __typename?: 'RoundedRectangle';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** The location of this object on the page. */
  location: Location;
  questionCaption?: Maybe<QuestionCaption>;
  borderObject?: Maybe<BorderObject>;
  borderMargins?: Maybe<BorderMargins>;
  borderStyleProperties?: Maybe<BorderStyleProperties>;
  backColor?: Maybe<Scalars['String']>;
  foreColor?: Maybe<Scalars['String']>;
};

export type Routing = {
  __typename?: 'Routing';
  fieldsToDisable?: Maybe<RoutingFields>;
  fieldsToEnable?: Maybe<RoutingFields>;
  gotoField: Scalars['Uuid'];
};

export type RoutingCondition = {
  __typename?: 'RoutingCondition';
  action: FieldStateAction;
  hasValue: Scalars['String'];
  whenField: Scalars['Uuid'];
};

export type RoutingField = {
  __typename?: 'RoutingField';
  guidId: Scalars['Uuid'];
};

export type RoutingFields = {
  __typename?: 'RoutingFields';
  fields: Array<RoutingField>;
};

export type RoutingRule = {
  __typename?: 'RoutingRule';
  conditions: Array<RoutingCondition>;
  fieldId: Scalars['Uuid'];
};

export enum ServiceResultCode {
  Unknown = 'UNKNOWN',
  Success = 'SUCCESS',
  Redirect = 'REDIRECT',
  Unauthorised = 'UNAUTHORISED',
  NotFound = 'NOT_FOUND',
  InvalidData = 'INVALID_DATA',
  Error = 'ERROR'
}

export type SubQuestionField = {
  __typename?: 'SubQuestionField';
  guidId: Scalars['Uuid'];
};

export type SubmissionError = {
  __typename?: 'SubmissionError';
  message: Scalars['String'];
};

export type SubmissionInput = {
  isLegacyReceipt?: Maybe<Scalars['Boolean']>;
  operation: RespondentOperation;
  projectId?: Maybe<Scalars['Int']>;
  receipt?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  responses: Array<FieldResponseInput>;
};

export type SubmissionResult = {
  __typename?: 'SubmissionResult';
  completionAction?: Maybe<CompletionAction>;
  isSuccess: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  respondent: Respondent;
  resultCode: ServiceResultCode;
  submissionErrors: Array<SubmissionError>;
  validationErrors: Array<ValidationError>;
};

export type TextBox = ObjectBase & DesignObjectBase & Colours & {
  __typename?: 'TextBox';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  htmlText?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
  foreColor?: Maybe<Scalars['String']>;
  /** The location of this object on the page. */
  location: Location;
  questionCaption?: Maybe<QuestionCaption>;
  text?: Maybe<Scalars['String']>;
  /** Number of degrees to rotate the text. */
  textRotation: Scalars['Int'];
  borderObject?: Maybe<BorderObject>;
};

export type Tickbox = ObjectBase & {
  __typename?: 'Tickbox';
  /** ID of the codelist code that this tickbox refers to. */
  codeId: Scalars['Uuid'];
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  /** Indicates whether the tickbox should be rendered as a hidden box. */
  hidden: Scalars['Boolean'];
  /** The location of this object on the page. */
  location: Location;
  routing?: Maybe<Routing>;
  /**
   * If this tickbox is part of a tickbox matrix, the 1-based index of the
   * subquestion that this box relates to. Otherwise null.
   */
  subQuestionIndex?: Maybe<Scalars['Int']>;
};

export type TickboxGroup = ObjectBase & DesignObjectBase & CaptureObjectBase & Colours & {
  __typename?: 'TickboxGroup';
  alternateBackStyle: AlternateBackStyle;
  equalRowSpacing: Scalars['Boolean'];
  equalColumnSpacing: Scalars['Boolean'];
  cosmeticItems: Array<CosmeticItem>;
  responsePositioning?: Maybe<ResponsePositioning>;
  /** The size of this object on the page. */
  dimensions: Dimensions;
  /** The field ID of this tickbox group. For tickbox matrices, use the SubQuestionFields property. */
  fieldId: Scalars['Uuid'];
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  isMatrix: Scalars['Boolean'];
  layer: Scalars['Int'];
  /** The location of this object on the page. */
  location: Location;
  name: Scalars['String'];
  questionCaption?: Maybe<QuestionCaption>;
  questionText?: Maybe<CosmeticItem>;
  responses?: Maybe<Responses>;
  subQuestionFields: Array<SubQuestionField>;
  tickboxes: Array<Tickbox>;
  tickboxResponses: Array<TickboxResponse>;
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export type TickboxResponse = {
  __typename?: 'TickboxResponse';
  box: Tickbox;
  label: CosmeticItem;
  ordinal: Scalars['Int'];
};


export type UrlLink = ObjectBase & DesignObjectBase & Colours & {
  __typename?: 'UrlLink';
  /** The size of this object on the page. */
  dimensions: Dimensions;
  fieldId?: Maybe<Scalars['Uuid']>;
  /** Unique ID of this object. */
  guidId: Scalars['Uuid'];
  htmlText?: Maybe<Scalars['String']>;
  /** The location of this object on the page. */
  location: Location;
  navigationType: NavigationType;
  pageNavigationType: PageNavigationType;
  pageNumber: Scalars['Int'];
  questionCaption?: Maybe<QuestionCaption>;
  text?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['Url']>;
  opensInNewTab?: Maybe<Scalars['Boolean']>;
  foreColor?: Maybe<Scalars['String']>;
  backColor?: Maybe<Scalars['String']>;
};

export type UserIdentifiersInput = {
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type UserInput = {
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  groups: Array<GroupInput>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type ValidationError = {
  __typename?: 'ValidationError';
  fieldId: Scalars['Uuid'];
  message: Scalars['String'];
};

export enum VerticalAlignment {
  Top = 'TOP',
  Centre = 'CENTRE',
  Bottom = 'BOTTOM'
}

export type WebGroup = CsvDownloadPermissions & {
  __typename?: 'WebGroup';
  authorisedProjects: Array<Scalars['Int']>;
  canDownloadSubmittedDataAsCsv: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type WebTheme = {
  __typename?: 'WebTheme';
  id: Scalars['Int'];
  data: Scalars['String'];
};

export type WebUser = CsvDownloadPermissions & {
  __typename?: 'WebUser';
  authorisedProjects: Array<Scalars['Int']>;
  canDownloadSubmittedDataAsCsv: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  groups: Array<WebGroup>;
  id: Scalars['Int'];
  mobileNumber?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type WebUserProperties = {
  __typename?: 'WebUserProperties';
  isAccountDisabled: Scalars['Boolean'];
  isPasswordChangeRequired: Scalars['Boolean'];
};
