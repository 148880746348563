import React, { FC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from '../Spinner/Spinner';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor:'rgba(0, 0, 0, 0.04)',
  },
}));

const PageSpinner: FC = () => {
  const classes = useStyles();
  
  return (
    <Backdrop open={true} className={classes.backdrop}>
      <Spinner width="4rem" height="4rem" />
    </Backdrop>
  )
};

export default PageSpinner;
